import * as React from 'react';
import {FC} from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";

const AppHeaderBurgerComponent: FC = () => {
  const [isOpened, setIsOpened] = React.useState(false);

  const navigate = useNavigate();

  const toggleDrawer =
      (open: boolean) =>
          (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
              return;
            }
            setIsOpened(open);
          };

  return (
      <Box>
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2}}
            onClick={toggleDrawer(true)}>
          <MenuIcon/>
        </IconButton>
        <SwipeableDrawer
            anchor={"left"}
            open={isOpened}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >
          <Box
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
          >
              <Typography
                  variant="h6"
                  component="p"
                  align={"center"}
                  sx={{
                    pt: 1,
                    pb: 1,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
              >
                Навигация
              </Typography>
            <Divider/>
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => {
                  navigate("/");
                }}>
                  <ListItemIcon>
                    <InboxIcon/>
                  </ListItemIcon>
                  <ListItemText primary={"Сотрудники"}/>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => {
                  navigate("/payments");
                }}>
                  <ListItemIcon>
                    <InboxIcon/>
                  </ListItemIcon>
                  <ListItemText primary={"Выплаты"}/>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </SwipeableDrawer>
      </Box>
  );
}

export default AppHeaderBurgerComponent;