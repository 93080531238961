import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import EmployeeDetailsComponent from "./components/employeeDetails/EmployeeDetailsComponent";
import AppInitialViewComponent from "./components/AppInitialViewComponent";
import {EmployeeEditModal} from "./components/employee/dialogs/EmployeeEditModal";
import EmployeeListCRUDComponent from "./components/employee/EmployeeListCRUDComponent";
import PaymentsComponent from "./components/payments/PaymentsComponent";
import CommonNotificationsComponent from "./components/notifications/CommonNotificationsComponent";

function App() {
  return (

      <div className="App">
        <EmployeeEditModal/>
        <CommonNotificationsComponent/>
        <Routes>
          <Route path="/" element={<AppInitialViewComponent/>}>
            <Route index element={<EmployeeListCRUDComponent/>}/>
            <Route path="employee/:employeeIdParam" element={<EmployeeDetailsComponent/>}/>
            <Route path="/payments" element={<PaymentsComponent/>}/>
          </Route>
        </Routes>
      </div>
  );
}

export default App;