import * as React from 'react';
import {FC, useEffect} from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../redux/hooks/hooks";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from "@mui/icons-material/Edit";
import TelegramIdButton from "../buttons/TelegramIdButton";
import {useNavigate, useParams} from "react-router-dom";
import {changeEditEmployeeDialogFlag} from "../../redux/reducer/dialogSlice";
import {UPDATE_TYPE} from "../../helpers/enum/updateType";
import {deleteEmployee, fetchEmployeeById, updateEmployee} from "../../redux/reducer/employeeSlice";
import {Circle} from "@mui/icons-material";
import {useConfirm} from "material-ui-confirm";

const EmployeeDetailsComponent: FC = () => {
  const dispatch = useAppDispatch();
  const {employeeIdParam} = useParams();
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.employees.loading);
  const selectedEmployee = useAppSelector((state) => state.employees.employeeDetails);

  const confirm = useConfirm();

  useEffect(() => {
    if (employeeIdParam !== undefined) {
      dispatch(fetchEmployeeById(employeeIdParam));
    }
  }, [employeeIdParam]);

  const onEditButtonClick = () => {
    dispatch(changeEditEmployeeDialogFlag({
      employeeToEdit: selectedEmployee,
      isEditEmployeeModalOpened: true,
      editType: UPDATE_TYPE.EDIT
    }));
  }

  const onChangeEmployeeStatusButtonClick = (status: boolean) => {
    confirm({
      title: "Статус сотрудника будет изменён",
      description: "Подтвердить?",
      confirmationText: 'Да',
      cancellationText: 'Нет'
    })
    .then(() => {
      dispatch(updateEmployee({...selectedEmployee, active: status}));
    });
  }

  const onDeleteButtonClick = () => {
    confirm({
      title: "Сотрудник будет удален",
      description: "Подтвердить?",
      confirmationText: 'Да',
      cancellationText: 'Нет'
    })
    .then(() => {
      dispatch(deleteEmployee(selectedEmployee.id));
      navigate("/");
    });
  }

  return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <Button onClick={() => navigate("/")}>
                  <ArrowBackIcon/>
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" gutterBottom component="div">
                  <Tooltip placement="top-end" title={
                    <h3> {selectedEmployee.active ? "Аккаунт активирован" : "Аккаунт отключен"} </h3>}>
                    {selectedEmployee.active ? <Circle fontSize="small" color="success"/> :
                        <Circle color="error"/>}
                  </Tooltip>
                  {selectedEmployee.name}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6" gutterBottom component="div">
                  Статус: {selectedEmployee.active ? "Работает" : "Уволен(а)/Акаунт отключен"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={3}>
                <Stack>
                  {selectedEmployee.active &&
                  <Button onClick={() => onChangeEmployeeStatusButtonClick(false)}>
                    Уволить
                  </Button>}
                  {!selectedEmployee.active &&
                  <Button onClick={() => onChangeEmployeeStatusButtonClick(true)}>
                    Восстановить
                  </Button>}
                  {!selectedEmployee.active &&
                  <Button onClick={onDeleteButtonClick}>
                    Удалить навсегда
                  </Button>}
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <Button onClick={onEditButtonClick}><EditIcon/></Button>
              </Grid>
              <Grid item xs={1}>
                <TelegramIdButton text="Telegram"
                                  telegramLink={selectedEmployee.telegramLink}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            Some data:
          </Grid>
          <Grid item xs={4}>
            Some other data:
          </Grid>
          <Grid item xs={4}>
            Some other data:
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Card>
              <CardHeader title="Выплаты"
              />
              <CardContent>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardHeader title="Какие-то данные"/>
              <CardContent>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Backdrop
            sx={
              {
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1
              }
            }
            open={loading}>
          <CircularProgress color="primary"/>
        </Backdrop>
      </Box>
  );
}
export default EmployeeDetailsComponent;
