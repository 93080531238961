export interface Unit {
  code: string;
  techName: string;
  name: string;
}

export const initialUnit: Unit = {
  code: '',
  techName: '',
  name: ''

}

export const UNIT_DEFAULT_API_PAGE_SIZE = 100;