import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {Employee, initialEmployee} from "../../../domain/employee";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from "@mui/material";
import {UPDATE_TYPE} from "../../../helpers/enum/updateType";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks/hooks";
import {Unit} from "../../../domain/unit";
import {InputElementOptions} from "../../../domain/inputElementOptions";
import {changeEditEmployeeDialogFlag} from "../../../redux/reducer/dialogSlice";
import {useConfirm} from "material-ui-confirm";
import {createEmployee, updateEmployee} from "../../../redux/reducer/employeeSlice";
import {objectsEquals} from "../../../helpers/objects";

interface EmployeeEditFormModalProps {
}

interface EmployeeEditModalInputOptions {
  name: InputElementOptions,
  telegramId: InputElementOptions,
  telegramLink: InputElementOptions,
  email: InputElementOptions
}

const initialElementOptions: EmployeeEditModalInputOptions = {
  name: {
    errorText: "Неправильный формат имени",
    isNotValid: false
  },
  telegramId: {
    errorText: "TelegramID состоит только из цифр",
    isNotValid: false
  },
  telegramLink: {
    errorText: "Неверный формат TG ссылки",
    isNotValid: false
  },
  email: {
    errorText: "Неправильный email",
    isNotValid: false
  }
};

type EmpEditModalInputOptsKey = keyof EmployeeEditModalInputOptions;

export const EmployeeEditModal: React.FC<EmployeeEditFormModalProps> = ({}) => {
  const dispatch = useAppDispatch();
  const [editingEmployee, setEditingEmployee] = useState<Employee>({...initialEmployee});
  const [elementOptions, setElementOptions] = useState<EmployeeEditModalInputOptions>({...initialElementOptions});

  const confirm = useConfirm();

  const editingEmpState = useAppSelector((state) => state.dialog.editingEmp);
  const unitState = useAppSelector((state) => state.units);

  useEffect(() => {
    if (editingEmpState.employeeToEdit !== null) {
      setEditingEmployee({...editingEmpState.employeeToEdit});
    } else {
      setEditingEmployee({...initialEmployee});
    }

  }, [editingEmpState, unitState]);

  const resetDialogState = () => {
    dispatch(changeEditEmployeeDialogFlag({
      employeeToEdit: null,
      isEditEmployeeModalOpened: false
    }));
    setElementOptions({...initialElementOptions});
  }

  const handleClose = () => {
    if (!objectsEquals(editingEmployee, editingEmpState.employeeToEdit)
        && !objectsEquals(editingEmployee, initialEmployee)) {
      confirm({
        title: "Данные были изменены",
        description: "Закрыть окно?",
        confirmationText: 'Да',
        cancellationText: 'Нет'
      })
      .then(() => {
        resetDialogState();
      })
    } else {
      resetDialogState();
    }
  };

  const handleTextFieldChange = (event: ChangeEvent<HTMLTextAreaElement>, regex: RegExp | null) => {
    let field = event.target.id;
    let inputValue = event.target.value;
    setEditingEmployee({...editingEmployee, [field]: inputValue});

    if (regex != null) {
      setElementOptions({
        ...elementOptions,
        [field]: {
          ...elementOptions[field as EmpEditModalInputOptsKey],
          isNotValid: !regex.test(inputValue)
        }
      });
    }
  }

  const handleSelectUnitItemChange = (event: SelectChangeEvent) => {
    let unitCode = event.target.value;
    let newUnit = unitState.units.find((unit) => {
      return unit.code === unitCode;
    });
    setEditingEmployee({...editingEmployee, unit: {...newUnit} as Unit});
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    confirm({
      title: "Подтверждение",
      description: "Применить изменения?",
      confirmationText: 'Да',
      cancellationText: 'Нет'
    })
    .then(() => {

      switch (editingEmpState.editType) {
        case UPDATE_TYPE.EDIT: {
          dispatch(updateEmployee(editingEmployee));
          break;
        }
        case UPDATE_TYPE.CREATE: {
          let employeeWithDefaultUnit = {...editingEmployee};
          if(editingEmployee.unit === null) {
            employeeWithDefaultUnit = {...editingEmployee, unit: unitState.initialUnit};
          }

          dispatch(createEmployee(employeeWithDefaultUnit));
          break;
        }
        default: {
          console.log("NO SUCH UPDATE TYPE");
          break;
        }
      }

      dispatch(changeEditEmployeeDialogFlag({
        employeeToEdit: null,
        isEditEmployeeModalOpened: false
      }));
    });
  }

  return (
      <Dialog open={editingEmpState.isEditEmployeeModalOpened} onClose={handleClose}>
        <DialogTitle>
          {editingEmpState.editType === UPDATE_TYPE.EDIT ? 'Изменить сотрудника' : "Добавить сотрудника вручную"}
        </DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                    error={(elementOptions["name"]).isNotValid}
                    helperText={(elementOptions["name"]).isNotValid ?
                        (elementOptions["name"]).errorText : ''}
                    id="name"
                    margin="normal"
                    required
                    fullWidth
                    label="Имя сотрудника"
                    autoComplete="Имя"
                    value={editingEmployee.name}
                    onChange={(event) => {
                      handleTextFieldChange(event as ChangeEvent<HTMLTextAreaElement>, null);
                    }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    error={(elementOptions["telegramId"]).isNotValid}
                    helperText={(elementOptions["telegramId"]).isNotValid ?
                        (elementOptions["telegramId"]).errorText : ''}
                    id="telegramId"
                    required
                    fullWidth
                    label="Телеграм id"
                    autoComplete="Telegram Id"
                    value={editingEmployee.telegramId}
                    onChange={(event) => {
                      handleTextFieldChange(event as ChangeEvent<HTMLTextAreaElement>, new RegExp(/^\d+$/));
                    }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    error={(elementOptions["telegramLink"]).isNotValid}
                    helperText={(elementOptions["telegramLink"]).isNotValid ?
                        (elementOptions["telegramLink"]).errorText : ''}
                    id="telegramLink"
                    required
                    fullWidth
                    label="Ссылка на телеграм"
                    autoComplete="Ссылка на телеграм"
                    value={editingEmployee.telegramLink}
                    onChange={(event) => {
                      handleTextFieldChange(event as ChangeEvent<HTMLTextAreaElement>, new RegExp(/^((https?:\/\/)((t|telegram)\.me)\/)[a-zA-Z0-9_]{5,32}$/gm));
                    }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    error={(elementOptions["email"]).isNotValid}
                    helperText={(elementOptions["email"]).isNotValid ?
                        (elementOptions["email"]).errorText : ''}
                    id="email"
                    fullWidth
                    name="Электронная почта"
                    label="Электронная почта"
                    type="email"
                    autoComplete="Электронная почта"
                    value={editingEmployee.email}
                    onChange={(event) => {
                      handleTextFieldChange(event as ChangeEvent<HTMLTextAreaElement>, new RegExp(/^$|([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/))
                    }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="unit">Подразделение</InputLabel>
                  <Select
                      id="unit"
                      value={editingEmployee.unit !== null ?
                          editingEmployee.unit.code : unitState.initialUnit.code}
                      label="Подразделение"
                      onChange={handleSelectUnitItemChange}
                  >
                    {
                      unitState.units.map((unit) => (
                          <MenuItem key={unit.code} value={unit.code}>{unit.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                <Button type="submit" variant="contained">Subscribe</Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
  )
      ;
};