import {FC, useEffect} from "react";
import {fetchAllUnits} from "../redux/reducer/unitSlice";
import {useAppDispatch} from "../redux/hooks/hooks";

export const AppInitialDataLoaderComponent: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllUnits());
  }, []);
  return (
      <div style={{display: "none"}}>
      </div>
  );
}