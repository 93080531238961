import * as React from 'react';
import {FC} from 'react';
import {Button, Menu, MenuItem, Stack} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {useAppDispatch} from "../../../redux/hooks/hooks";
import {Employee} from "../../../domain/employee";
import {changeEditEmployeeDialogFlag} from "../../../redux/reducer/dialogSlice";
import {UPDATE_TYPE} from "../../../helpers/enum/updateType";
import {useNavigate} from "react-router-dom";

interface EmpListButtonsProps {
  employee: Employee;
}

const EmployeeListCellButtonsComponent: FC<EmpListButtonsProps> = ({employee}) => {

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useAppDispatch();

  const onEditButtonClick = () => {
    dispatch(changeEditEmployeeDialogFlag({
      employeeToEdit: employee,
      isEditEmployeeModalOpened: true,
      editType: UPDATE_TYPE.EDIT
    }));
  }

  const handleOnEmpDetailsClick = (employeeId: string) => {
    navigate("/employee/" + employeeId);
  };

  return (
      <Stack alignContent="right" textAlign="right" direction="row">
        <Button size="small" color="primary" aria-label="add" onClick={onEditButtonClick}>
          <EditIcon/>
        </Button>
        <Button size="small" color="primary" aria-label="add"
                onClick={() => handleOnEmpDetailsClick(employee.id)}>
          Просмотр
        </Button>
        <Button onClick={handleClick} size="small" color="primary" aria-label="add">
          ...
        </Button>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu>
      </Stack>
  );
}
export default EmployeeListCellButtonsComponent;
