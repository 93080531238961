import * as React from 'react';
import {FC} from 'react';
import {Box, Button} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate} from "react-router-dom";

const PaymentsComponent: FC = () => {
  const navigate = useNavigate();

  return (
      <Box>
        <Button onClick={() => navigate("/")}>
          <ArrowBackIcon/>
        </Button>
        Выплаты
      </Box>
  );
}
export default PaymentsComponent;
