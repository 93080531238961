import {Unit} from "./unit";

export interface Employee {
  id: string;
  telegramId: string;
  telegramLink: string;
  name: string;
  active: boolean,
  email: string;
  unit: Unit | null;
}

export const initialEmployee: Employee = {
  id: '',
  telegramId: '',
  name: '',
  email: '',
  active: false,
  telegramLink: '',
  unit: null
}

export const EMP_DEFAULT_API_PAGE_SIZE = 100;