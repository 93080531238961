import * as React from 'react';
import {FC} from 'react';
import {Button} from "@mui/material";
import {useAppDispatch} from "../../redux/hooks/hooks";
import TelegramIcon from '@mui/icons-material/Telegram';

interface EmpListButtonsProps {
  text: string;
  telegramLink: string;
}

const TelegramIdButton: FC<EmpListButtonsProps> = ({text, telegramLink}) => {

  const dispatch = useAppDispatch();

  const handleClick = () => {
    window.open(telegramLink, "_blank", 'noopener,noreferrer')
  }

  return (
      <Button size="small" color="primary" aria-label="add" onClick={handleClick}>
        <TelegramIcon/>{text}
      </Button>
  );
}
export default TelegramIdButton;
