import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {EMP_DEFAULT_API_PAGE_SIZE, Employee, initialEmployee} from "../../domain/employee";
import {geminiApi} from "../../helpers/geminiApi";
import {APIPATHS} from "../../constants/apiPath";
import {Page} from "../../domain/api/page";
import {SearchParams} from "../../domain/api/searchParams";
import {initialPageInfo, PageInfo} from "../../domain/api/pageInfo";
import {NotificationState, popNotification, pushNotification} from "../common/notifications";


export const fetchEmployeesWithFilter = createAsyncThunk<Page<Employee>, SearchParams, {}>(
    'employeeList/getEmployeesWithFilter',
    async function (searchParams: SearchParams) {

      let response;

      if (searchParams.rsqlString === null) {
        response = await geminiApi.get(APIPATHS.EMPV1PATH, {
          params: {
            page: searchParams.page.number,
            size: searchParams.page.size
          }
        });
      } else {
        response = await geminiApi.get(APIPATHS.EMPV1PATH, {
          params: {
            page: searchParams.page.number,
            size: searchParams.page.size,
            search: searchParams.rsqlString
          }
        });
      }

      console.log("employeeList/getEmployeesWithFilter response result :");
      console.log(response.data);
      return response.data;
    }
);

export const fetchEmployeeById = createAsyncThunk<Employee, string, {}>(
    'employeeList/fetchEmployeeById',
    async function (id) {
      const response = await geminiApi.get(`${APIPATHS.EMPV1PATH}/${id}`)

      console.log("employeeList/fetchEmployeeByTelegramId response result :");
      console.log(response.data);
      return response.data;
    }
);

export const createEmployee = createAsyncThunk<Employee, Employee, {}>(
    'employeeList/createEmployee',
    async function (empToCreate) {
      const response = await geminiApi.post(APIPATHS.EMPV1PATH, {
        ...empToCreate
      });

      console.log("employeeList/createEmployee response result :");
      console.log(response.data);
      return response.data;
    }
);

export const updateEmployee = createAsyncThunk<Employee, Employee, {}>(
    'employeeList/updateEmployee',
    async function (empToUpdate) {
      const response = await geminiApi.put(APIPATHS.EMPV1PATH, {
        ...empToUpdate
      });

      console.log("employeeList/updateEmployee response result :");
      console.log(response.data);
      return response.data;
    }
);

export const deleteEmployee = createAsyncThunk<void, string, {}>(
    'employeeList/deleteEmployee',
    async function (id) {
      const response = await geminiApi.delete(`${APIPATHS.EMPV1PATH}/${id}`);

      console.log("employeeList/deleteEmployee response result :");
      console.log(response.data);
    }
);
export type EmployeeListSearchFilterState = {
  active: [string, boolean] | null;
}

type EmployeeState = NotificationState & {
  employees: Employee[];
  employeeDetails: Employee;
  listPageInfo: PageInfo,
  apiPageInfo: PageInfo
  totalElements: number,
  loading: boolean,
  error: string | null,
  employeeListSearchFilter: EmployeeListSearchFilterState
}

const initialEmployeeState: EmployeeState = {
  employees: [],
  employeeDetails: initialEmployee,
  listPageInfo: {...initialPageInfo},
  apiPageInfo: {size: EMP_DEFAULT_API_PAGE_SIZE, number: 0},
  totalElements: 0,
  loading: false,
  error: null,
  employeeListSearchFilter: {
    //RSQL filter, filter value
    active: ["(active==true)", true],
  },
  notifications: [],
}

const employeeSlice = createSlice({
  name: "employeeList",
  initialState: initialEmployeeState,
  reducers: {
    changeEmployeeListSearchFilter: (state, action: PayloadAction<EmployeeListSearchFilterState>) => {
      state.employeeListSearchFilter = action.payload;
    },
    pushEmpNotification: pushNotification,
    popEmpNotification: popNotification,
  },
  extraReducers: (builder => {
    builder
    .addCase(fetchEmployeesWithFilter.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchEmployeesWithFilter.fulfilled, (state, action) => {
      state.loading = false;
      state.employees = action.payload.content;
      state.totalElements = action.payload.totalElements;
      state.listPageInfo.number = action.payload.number;
      state.listPageInfo.size = action.payload.size;
    })
    .addCase(fetchEmployeesWithFilter.rejected, (state, action) => {
      state.notifications.push({
        message: "Ошибка при загрузке списка работников",
        options: {variant: 'error'}
      });
    })

    .addCase(fetchEmployeeById.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchEmployeeById.fulfilled, (state, action) => {
      state.loading = false;
      state.employeeDetails = action.payload;
    })
    .addCase(fetchEmployeeById.rejected, (state, action) => {
      state.notifications.push({
        message: "Ошибка при загрузке информации о работнике",
        options: {variant: 'error'}
      });
    })

    .addCase(createEmployee.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(createEmployee.fulfilled, (state, action) => {
      let filterState = state.employeeListSearchFilter as EmployeeListSearchFilterState;
      let createdEmp = action.payload as Employee;
      let isFilteredOut = false;

      Object.keys(filterState).forEach((fieldName) => {
        let filterValue = filterState[fieldName as keyof EmployeeListSearchFilterState];
        if (filterValue !== null
            && createdEmp[fieldName as keyof Employee] !== filterValue[1]) {
          isFilteredOut = true;
        }
      });

      if (!isFilteredOut) {
        state.employees.push(createdEmp);
      }

      state.loading = false;
      state.totalElements = state.totalElements + 1;

      state.notifications.push({
        message: "Работник создан",
        options: {variant: 'success'}
      });
    })
    .addCase(createEmployee.rejected, (state, action) => {
      state.notifications.push({
        message: "Ошибка при создании работника",
        options: {variant: 'error'}
      });
    })

    .addCase(updateEmployee.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(updateEmployee.fulfilled, (state, action) => {
      state.loading = false;
      let id = action.payload.id;
      let foundIndex = state.employees.findIndex((emp) => emp.id === id);
      state.employees[foundIndex] = action.payload;
      state.employeeDetails = action.payload;

      state.notifications.push({
        message: "Работник обновлен",
        options: {variant: 'success'}
      });
    })
    .addCase(updateEmployee.rejected, (state, action) => {
      state.notifications.push({
        message: "Ошибка при обновлении работника",
        options: {variant: 'error'}
      });
    })

    .addCase(deleteEmployee.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(deleteEmployee.fulfilled, (state, action) => {
      state.loading = false;
      state.employees = state.employees.filter((item) =>
          item.id !== state.employeeDetails.id
      );
      state.totalElements = state.totalElements - 1;
      state.employeeDetails = initialEmployee;

      state.notifications.push({
        message: "Работник удален",
        options: {variant: 'success'}
      });
    })
    .addCase(deleteEmployee.rejected, (state, action) => {
      state.notifications.push({
        message: "Ошибка при удалении работника",
        options: {variant: 'error'}
      });
    })
  })
});

export const {
  changeEmployeeListSearchFilter,
  pushEmpNotification,
  popEmpNotification
} = employeeSlice.actions;

export default employeeSlice.reducer;