import * as React from 'react';
import {ChangeEvent, FC, useEffect} from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {employeeListColumnLabesls} from '../../../constants/employeeListColumnLabesls'
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableBody,
  Tooltip
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks/hooks";
import {initialPageInfo} from "../../../domain/api/pageInfo";
import {EMP_STATUS} from "../../../helpers/enum/empStatus";
import {
  changeEmployeeListSearchFilter,
  EmployeeListSearchFilterState,
  fetchEmployeesWithFilter
} from "../../../redux/reducer/employeeSlice";
import {Circle} from "@mui/icons-material";
import TelegramIdButton from "../../buttons/TelegramIdButton";
import EmployeeListCellButtonsComponent from "../buttonsets/EmployeeListCellButtonsComponent";
import {useLocation} from "react-router-dom";

const EmployeeListComponent: FC = () => {

  const employees = useAppSelector((state) => state.employees.employees);
  const totalElements = useAppSelector((state) => state.employees.totalElements);
  const listPageInfo = useAppSelector((state) => state.employees.listPageInfo);
  const rowsPerPageOptions = [initialPageInfo.size, 25, 50, 100];
  const empSearchFilterState = useAppSelector((state) => state.employees.employeeListSearchFilter);

  const location = useLocation();

  useEffect(() => {
    let rsqlResult = buildEmpRsqlString(empSearchFilterState, ";");
    dispatch(fetchEmployeesWithFilter({
      page: {number: listPageInfo.number, size: listPageInfo.size},
      rsqlString: rsqlResult !== "" ? rsqlResult : null
    }));
  }, [location.key]);

  const dispatch = useAppDispatch();

  const buildEmpRsqlString = (empListSearchFilterState: EmployeeListSearchFilterState,
                              separator: string): string => {
    return Object.values(empSearchFilterState)
    .filter((fieldVal) => fieldVal != null)
    .map((fieldVal) => (fieldVal as string[])[0]).join(separator);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    let rsqlResult = buildEmpRsqlString(empSearchFilterState, ";");

    dispatch(fetchEmployeesWithFilter({
      page: {number: newPage, size: listPageInfo.size},
      rsqlString: rsqlResult !== "" ? rsqlResult : null
    }));
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    let rsqlResult = buildEmpRsqlString(empSearchFilterState, ";");

    dispatch(fetchEmployeesWithFilter({
      page: {number: initialPageInfo.number, size: +event.target.value},
      rsqlString: rsqlResult !== "" ? rsqlResult : null
    }));
  };

  const handleFilterEmployeeByActive = (event: SelectChangeEvent) => {
    let selectedFilter = event.target.value;
    switch (selectedFilter) {
      case "Все": {
        dispatch(changeEmployeeListSearchFilter({...empSearchFilterState, active: null}));
        dispatch(fetchEmployeesWithFilter({page: initialPageInfo, rsqlString: null}));
        break;
      }
      case EMP_STATUS.ACTIVE: {
        dispatch(changeEmployeeListSearchFilter({
          ...empSearchFilterState,
          active: ["(active==true)", true]
        }));
        dispatch(fetchEmployeesWithFilter({page: initialPageInfo, rsqlString: "(active==true)"}));
        break;
      }
      case EMP_STATUS.INACTIVE: {
        dispatch(changeEmployeeListSearchFilter({
          ...empSearchFilterState,
          active: ["(active==false)", false]
        }));
        dispatch(fetchEmployeesWithFilter({page: initialPageInfo, rsqlString: "(active==false)"}));
        break;
      }
    }
  }

  return (
      <Box sx={{m: 1.5}}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Grid container>
              <Grid item xs={6}>
                <FormControl variant='standard' margin='dense' size="medium" fullWidth>
                  <InputLabel id="active" margin='dense'>Показываются: </InputLabel>
                  <Select
                      id="active"
                      value={empSearchFilterState.active !== null ?
                          (empSearchFilterState.active[1] ? EMP_STATUS.ACTIVE : EMP_STATUS.INACTIVE) : "Все"}
                      onChange={handleFilterEmployeeByActive}>
                    <MenuItem key={"Все"}
                              value={"Все"}>{"Все"}
                    </MenuItem>
                    <MenuItem key={EMP_STATUS.ACTIVE}
                              value={EMP_STATUS.ACTIVE}>{EMP_STATUS.ACTIVE}
                    </MenuItem>
                    <MenuItem key={EMP_STATUS.INACTIVE}
                              value={EMP_STATUS.INACTIVE}>{EMP_STATUS.INACTIVE}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer sx={{minHeight: 500, maxHeight: 500}} component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {employeeListColumnLabesls.map((column) => (
                    <TableCell key={column}>
                      {column}
                    </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.slice(0, listPageInfo.size).map((emp) => (
                  <TableRow hover key={emp.id}>
                    <TableCell scope="row">
                      <Grid container>
                        <Grid item xs={2}>
                          <Tooltip placement="top-end" title={
                            <h3> {emp.active ? "Аккаунт активирован" : "Аккаунт отключен"} </h3>}>
                            {emp.active ? <Circle fontSize="small" color="success"/> :
                                <Circle color="error"/>}
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10}>
                          {emp.name}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell scope="row">Не запрошено</TableCell>
                    <TableCell scope="row">{emp.unit == null ? '' : emp.unit.name}</TableCell>
                    <TableCell scope="row">
                      <TelegramIdButton telegramLink={emp.telegramLink}
                                        text={emp.telegramId}/>
                    </TableCell>
                    <TableCell scope="row">{emp.email}</TableCell>
                    <TableCell scope="row">
                      <EmployeeListCellButtonsComponent employee={emp}/>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={totalElements}
            rowsPerPage={listPageInfo.size}
            page={listPageInfo.number}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
  );
}

export default EmployeeListComponent;
