import {configureStore} from "@reduxjs/toolkit";
import employeeReducer from "./reducer/employeeSlice";
import unitReducer from "./reducer/unitSlice";
import dialogReducer from "./reducer/dialogSlice"

export const store = configureStore({
  reducer: {
    employees: employeeReducer,
    units: unitReducer,
    dialog: dialogReducer,
  }
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;