import * as React from 'react';
import {FC} from 'react';
import EmployeesListComponent from "./table/EmployeesListComponent";
import {Backdrop, CircularProgress, Paper} from "@mui/material";
import AddEmployeeComponent from "./buttonsets/AddEmployeeComponent";
import {useAppSelector} from "../../redux/hooks/hooks";

const EmployeeListCRUDComponent: FC = () => {

  const empLoadingStatus = useAppSelector((state) => state.employees.loading);

  return (
      <Paper elevation={6}
             sx={{position: "relative"}}>
        <AddEmployeeComponent/>
        <EmployeesListComponent/>
        <Backdrop
            sx={
              {
                position: 'absolute',
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1
              }
            }
            open={empLoadingStatus}>
          <CircularProgress color="primary"/>
        </Backdrop>
      </Paper>
  );
}
export default EmployeeListCRUDComponent;
