import * as React from 'react';
import {FC} from 'react';
import {Button, Grid} from "@mui/material";
import {useAppDispatch} from "../../../redux/hooks/hooks";
import {changeEditEmployeeDialogFlag} from "../../../redux/reducer/dialogSlice";
import {UPDATE_TYPE} from "../../../helpers/enum/updateType";
import {initialEmployee} from "../../../domain/employee";

const AddEmployeeComponent: FC = () => {

  const dispatch = useAppDispatch();

  const handleOpen = () => {
    dispatch(changeEditEmployeeDialogFlag({
      employeeToEdit: initialEmployee,
      isEditEmployeeModalOpened: true,
      editType: UPDATE_TYPE.CREATE
    }));
  };

  return (
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Button size="small" aria-label="add" onClick={handleOpen}>Добавить вручную</Button>
          <Button size="small" aria-label="add"> Добавить по инвайту</Button>
        </Grid>
        <Grid item md={9}>
        </Grid>
      </Grid>
  );
}
export default AddEmployeeComponent;
