import {Draft, PayloadAction} from "@reduxjs/toolkit";
import {Notification} from "../../domain/notifications/notification";

export const pushNotification = (state: Draft<any>, action: PayloadAction<Notification>) => {
  state.notifications.push(action.payload);
}

export const popNotification = (state: Draft<any>, action: PayloadAction<any>) => {
  state.notifications.pop();
}

export type NotificationState = {
  notifications: Notification[];
}

export const initNotificationState: NotificationState = {
  notifications: []
}
