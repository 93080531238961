import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {initialUnit, Unit} from "../../domain/unit";
import {geminiApi} from "../../helpers/geminiApi";
import {APIPATHS} from "../../constants/apiPath";
import {Page} from "../../domain/api/page";


export const fetchAllUnits = createAsyncThunk<Page<Unit>, undefined, {}>(
    "units/fetchAllUnits",
    async function () {
      let result = await geminiApi.get(APIPATHS.UNITV1PATH);

      console.log("Action result of units/fetchAllUnits: ")
      console.log(result.data);
      return result.data;
    }
);

export type UnitState = {
  units: Unit[];
  loading: boolean;
  error: string | null;
  initialUnit: Unit;
}

export const initialUnitState: UnitState = {
  units: [],
  loading: false,
  error: null,
  initialUnit: initialUnit
}

const unitSlice = createSlice({
  name: "units",
  initialState: initialUnitState,
  reducers: {},
  extraReducers: (builder => {
    builder
    .addCase(fetchAllUnits.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchAllUnits.fulfilled, (state, action) => {
      state.loading = false;
      state.units = action.payload.content;
      if (action.payload.content.length > 0) {
        state.initialUnit = action.payload.content[0];
      }
    })
    .addCase(fetchAllUnits.rejected, (state, action) => {
      state.loading = false;
      console.log("units/fetchAllUnits request failed");
    })
  })
});

export const {} = unitSlice.actions;

export default unitSlice.reducer;